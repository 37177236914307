<template>
  <td
    v-if="is_component"
    :class="cellClass"
    :style="cellStyles"
  >
    <keep-alive>
      <component
        :is="cellComponent"
        v-bind="currentProperties"
      />
    </keep-alive>
  </td>
  <td
    v-else-if="is_template"
    :class="cellClass"
    :style="cellStyles"
    v-html="rendering(cellData)"
  />
  <td
    v-else-if="is_libelle"
    :class="cellClass"
    :style="cellStyles"
  >
    {{ cellData[name]|libelle }}
  </td>
  <td
    v-else-if="is_date"
    :class="cellClass"
    :style="cellStyles"
  >
    {{ cellData[name]|showTime }}
  </td>
  <td
    v-else
    :class="cellClass"
    :style="cellStyles"
  >
    {{ cellData[name] }}
  </td>
</template>

<script>
import { TEXT_TYPE, TEMPLATE_TYPE, LIBELLE_TYPE, COMPONENT_TYPE, DATE_TYPE } from './dataType'
export default {
  name: 'DataCell',
  props: {
    type: { type: String, default: TEXT_TYPE },
    name: String,
    cellData: { type: Object, required: true },
    rendering: { type: Function },
    cellComponent: Function,
    componentProperties: { type: Object },
    cellClass: String,
    cellStyles: String
  },
  data () {
    return {}
  },
  methods: {},
  computed: {
    is_component () { return this.type === COMPONENT_TYPE },
    is_libelle () { return this.type === LIBELLE_TYPE },
    is_template () { return this.type === TEMPLATE_TYPE },
    is_date () { return this.type === DATE_TYPE },
    currentProperties () {
      return { object: this.cellData }
    }
  }
}
</script>

<style scoped>

</style>
